import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './App.sass';
import Appointment from './forms/Appointment/Appointment';
import ContactUs from './forms/ContactUs/ContactUs';
import { setGTMData } from './services/FormService';

const RECAPTCHA_SITE_KEY = new URL(document.location).searchParams.get('rKey');

export default function App() {
  useEffect(() => {
    const handleMessage = (e) => {
      const key = e.message ? 'message' : 'data';
      const data = e[key];

      if (typeof data === 'object' && data.type === 'hipaa-form:client-id') {
        setGTMData(data);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <Router>
        <Routes>
          <Route exact path="/appointment" element={<Appointment />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
        </Routes>
      </Router>
    </GoogleReCaptchaProvider>
  );
}
