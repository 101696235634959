import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import './Appointment.sass';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import TextField from '../../components/TextField/TextField';
import formSettings from './formSettings';
import { formatData, sendHipaaForm } from '../../services/FormService';
import Alert from '../../components/Alert/Alert';

const Appointment = function Appointment() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {}, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: async () => {
      const defaultValues = {};
      formSettings.fields.forEach((f) => (defaultValues[f.key] = f.defaultValue));
      return defaultValues;
    }
  });

  const setValue = (field, value) => {
    if (field.type === 'select') {
      return field.options?.find((c) => c.value === value);
    }
    return value;
  };

  const onSubmit = async (data) => {
    const token = await executeRecaptcha('submit_appointment');

    setIsSubmitting(true);

    try {
      const formattedData = formatData(data, formSettings);

      const response = await sendHipaaForm('appointment', formattedData, token);
      if (response?.status) {
        reset();
        setIsSuccess(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form-wrap">
      <CSSTransition in={isSuccess} unmountOnExit classNames="alert" timeout={300}>
        <Alert message={'Appointment successfully submitted'} onClose={() => setIsSuccess(false)} />
      </CSSTransition>
      <form onSubmit={handleSubmit(onSubmit)} className="appointment-form">
        {formSettings.fields.map((f) => (
          <div key={f.key}>
            <Controller
              defaultValue=""
              control={control}
              name={f.key}
              rules={{
                required: f.isRequired,
                maxLength: f.maxLength,
                pattern: f.pattern,
                validate: {
                  required: (v) => !f.isRequired || !!v.toString().trim()
                }
              }}
              render={({ field: { onChange, value, ref } }) => (
                <TextField
                  inputRef={ref}
                  name={f.key}
                  text={f.name + (f.isRequired ? '*' : '')}
                  type={f.type}
                  options={f.options}
                  placeholder={f.placeholder}
                  value={setValue(f, value)}
                  handleChange={(val) => onChange(val.value)}
                />
              )}
            />
            {errors[f.key] && errors[f.key].type === 'required' && (
              <div className="error">{f.name} is required</div>
            )}
            {errors[f.key] && errors[f.key].type === 'maxLength' && (
              <div className="error">Max length exceeded</div>
            )}
            {errors[f.key] && errors[f.key].type === 'pattern' && (
              <div className="error">{f.name} is invalid</div>
            )}
          </div>
        ))}
        <div>
          <SubmitButton text={formSettings.submitBtn.text} disabled={isSubmitting} />
        </div>
      </form>
    </div>
  );
};

export default Appointment;
