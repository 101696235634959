import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-number-input';
import InputMask from 'react-input-mask';

import 'react-phone-number-input/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import './TextField.sass';

const TextField = ({
  value = '',
  text = 'Text',
  type = 'text',
  className = 'form-input',
  handleChange = () => undefined,
  options = [],
  placeholder = ''
}) => {
  const [isFocus, setIsfocus] = useState(false);
  const handleFocus = () => setIsfocus(true);
  const handleBlur = () => setIsfocus(false);
  const handleChangeEvent = (target) => {
    handleChange(target);
  };

  let input = (
    <input
      type={type}
      value={value}
      onChange={(e) => handleChangeEvent(e.target)}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
  if (type === 'select') {
    input = (
      <Select
        value={value}
        placeholder={' '}
        options={options}
        isClearable={true}
        classNamePrefix="select"
        onChange={(value) => handleChange({ value: value ? value.value : '' })}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    );
  } else if (type === 'date') {
    input = (
      <DatePicker
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={value}
        popperPlacement="bottom"
        onChange={(date) => handleChangeEvent({ value: date })}
        onFocus={handleFocus}
        onBlur={handleBlur}
        customInput={<InputMask mask="99/99/9999" placeholder="dd/mm/yyyy" />}
      />
    );
  } else if (type === 'textarea') {
    input = (
      <textarea
        value={value}
        onChange={(e) => handleChangeEvent(e.target)}
        rows={3}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder || text}
      />
    );
  } else if (type === 'phone') {
    input = (
      <PhoneInput
        defaultCountry="US"
        international
        countryCallingCodeEditable={false}
        value={value}
        onChange={(e) => handleChangeEvent({ value: e })}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    );
  }

  return (
    <div
      className={
        className + ' input-' + type + (isFocus || value || type === 'textarea' ? ' active' : '')
      }>
      <label>{text}</label>
      {input}
    </div>
  );
};

TextField.propTypes = {
  value: PropTypes.any,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string
};

export default TextField;
